.counts {
    padding: 70px 0 60px;
  }
  
  .counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #f1f6fe;
  }
  
  .counts .count-box i {
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    background: #f89e46;
    padding: 12px;
    color: #fff;
    border-radius: 50px;
    border: 5px solid #fff;
  }
  
  .counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #062b5b;
  }
  
  .counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
  }
  