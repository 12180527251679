* {
    box-sizing: border-box;
    /* font-family:  */
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.5;
    color: #24292e;
    background-color: #fff;
  }
  label{
    font-weight: bold;
  }
  .app {
    margin: 2rem;
  }
  
  a {
    color: #08c;
  }
  
  /**
   * CUSTOM STYLE INPUTS
   */
  code {
    background: #eee;
    padding: 0.1rem;
    font-family: "Menlo";
    font-size: 13px;
    color: #ff00aa;
  }
  
  .text-input {
    padding: 0.5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .text-input:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
  
  .error .text-input {
    border-color: red;
  }
  
  .label {
    font-weight: bold;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .error .label {
    color: red;
  }
  
  .input-feedback {
    color: #999;
    margin-top: 0.25rem;
  }
  
  .error .input-feedback {
    color: red;
  }
  
  .animated {
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }

  
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  @keyframes shake {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }
  
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-10px, 0, 0);
    }
  
    20%,
    40%,
    60%,
    80% {
      transform: translate3d(10px, 0, 0);
    }
  }
  
  .shake {
    animation-name: shake;
  }
  
  /* OTHER STUFF */
  .input-feedback {
    color: red;
    margin-top: 0.25rem;
  }
  
  .dropdown-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  /** TYPOGRAPHY **/
  .text--size-large {
    font-size: 17px;
  }
  
  .text--size-default {
    font-size: 15px !important;
    line-height: 1 !important;
  }
  
  .text--size-small {
    font-size: 13px !important;
    line-height: 1.5;
  }
  
  .text--size-xsmall {
    font-size: 10px !important;
    line-height: 1.5;
  }
  
  h1 {
    font-weight: 700;
    font-size: 21px;
    line-height: 28px;
  }
  
  h2 {
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
    &.page-head {
      font-weight: 500;
      font-size: 17px;
      line-height: 28px;
      letter-spacing: 0.1px;
    }
  }
  
  h3 {
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
  }
  
  h4 {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  }
  
  /** PROGRESS BAR **/
  .progressbar-wrapper {
    width: 100%;
  }
  ol.progressbar {
    counter-reset: step;
    display: flex;
    justify-content: space-between;
    padding-left: 0px;
  }
  ol.progressbar li {
    list-style-type: none;
    /* float: left; */
    /* display: flex;
    justify-content: space-between; */
    width: 33%;
    position: relative;
    text-align: center;
  }
  
  ol.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid black;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
  }
  ol.progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
  }
  ol.progressbar li:first-child:after {
    content: none;
  }
  ol.progressbar li.active {
    color: #f89e46;
  }
  ol.progressbar li.active:before {
    /* border-color: green; */
    background-color: #f89e46;
    color: white;
    border-color: #f89e46;
  }
  
  ol.progressbar li.active + li:after {
    background-color: #f89e46;
  }
  