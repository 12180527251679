#hero {
    width: 100%;
    height: 75vh;
    background-size: cover;
    position: relative;
  }
  
  #hero:before {
    content: "";
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .container {
    position: relative;
    padding-top: 132px;
  }
  
  @media (max-width: 992px) {
    #hero .container {
      padding-top: 58px;
    }
  }
  
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #222222;
    font-family: "Poppins", sans-serif;
  }
  
  #hero h1 span {
    color: #f89e46;
  }
  
  #hero h2 {
    color: #555555;
    margin: 5px 0 30px 0;
    font-size: 24px;
    font-weight: 400;
  }
  
  #hero .btn-get-started {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #f89e46;
  }
  
  #hero .btn-get-started:hover {
    background: black;
    text-decoration: dashed !important;
  }
  
  #hero .btn-watch-video {
    font-size: 16px;
    display: inline-block;
    padding: 10px 25px 8px 40px;
    transition: 0.5s;
    margin-left: 25px;
    color: #222222;
    position: relative;
    font-weight: 600;
  }
  
  #hero .btn-watch-video i {
    color: #106eea;
    font-size: 32px;
    position: absolute;
    left: 0;
    top: 7px;
    transition: 0.3s;
  }
  
  #hero .btn-watch-video:hover {
    color: #106eea;
  }
  
  #hero .btn-watch-video:hover i {
    color: #3b8af2;
  }
  
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  
  @media (max-width: 768px) {
    #hero {
      height: 100vh;
    }
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    #hero .btn-get-started, #hero .btn-watch-video {
      font-size: 13px;
    }
  }
  
  @media (max-height: 500px) {
    #hero {
      height: 120vh;
    }
  }
  