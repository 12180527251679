.testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid rgba(255, 255, 255, 0.15);
    margin: 0 auto;
  }
  
  .testimonial-h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #fff;
  }
  
.testimonial-h4 {
    font-size: 14px;
    color: #ddd;
    margin: 0 0 15px 0;
  }