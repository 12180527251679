.featured-services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    text-align: center;
  }

  
  .featured-services .icon-box::before {
    content: '';
    position: absolute;
    background: #cbe0fb;
    right: 0;
    left: 0;
    bottom: 0;
    top: 100%;
    transition: all 0.3s;
    z-index: -1;
  }
  
  .featured-services .icon-box:hover::before {
    background: #f89e46;
    top: 0;
    border-radius: 0px;
  }
  
  .featured-services .icon-box .btn-get-started {
    font-family: "Roboto", sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: #f89e46;
    
  }
  .featured-services .icon-box:hover .btn-get-started {
    color: #fff;
  }

  .featured-services .icon-box .btn-get-started:hover {
    text-decoration: dashed !important;
    border-bottom: 1px solid dashed;
  }

  .featured-services .icon {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .featured-services .icon svg {
   
    line-height: 1;
    color: #f89e46;
  }
  .featured-services .icon-box:hover .icon svg {

    line-height: 1;
    color: white;
  }

  
  .featured-services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .featured-services .title a {
    color: #111;
  }
  
  .featured-services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  
  .featured-services .icon-box:hover .title a, .featured-services .icon-box:hover .description {
    color: #fff;
  }
  
  .featured-services .icon-box:hover .icon i {
    color: #fff;
  }
  