.about .content h3 {
    font-weight: 600;
    font-size: 26px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 35px;
  }
  
  .about .content ul li:first-child {
    margin-top: 35px;
  }
  
  .about .content ul i {
    background: #fff;
    box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
    font-size: 24px;
    padding: 20px;
    margin-right: 15px;
    color: #f89e46;
    border-radius: 50px;
  }
  
  .about .content ul h5 {
    font-size: 18px;
    color: #555555;
  }
  
  .about .content ul p {
    font-size: 15px;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f6f9fe;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
    background: #e7f1fd;
    color: #f89e46;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
  }
  
  .section-title h3 {
    margin: 15px 0 0 0;
    font-size: 32px;
    font-weight: 700;
  }
  
  .section-title h3 span {
    color: #f89e46;
  }
  
  .section-title p {
    margin: 15px auto 0 auto;
    font-weight: 600;
  }
  
  @media (min-width: 1024px) {
    .section-title p {
      width: 50%;
    }
  }
  